import React, { useState } from "react"
import { Panel } from "../component/Panel"
import { Loader } from "../component/Loader"
import { PageLayout } from "../component/PageLayout"

const FORM_STATE = {
  Start: "START",
  Loading: "LOADING",
  Failed: "FAILED",
  Success: "SUCCESS",
}

export default function Contact() {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")
  const [formState, setFormSstate] = useState(FORM_STATE.Start)
  const [submitted, setSubmitted] = useState(false)

  const onSubmit = e => {
    e.preventDefault()

    if (formState === FORM_STATE.Loading) {
      return null
    }

    var URL =
      "https://us-central1-revli-limited.cloudfunctions.net/addMessage?name=" +
      name +
      "&email=" +
      email +
      "&phone=" +
      phone +
      "&message=" +
      message

    setFormSstate(FORM_STATE.Loading)

    fetch(URL)
      .then(response => {
        setFormSstate(FORM_STATE.Success)
        setSubmitted(true)
      })
      .catch(e => {
        setFormSstate(FORM_STATE.Failed)
      })
  }

  const handleChange = e => {
    switch (e.target.name) {
      case "name":
        setName(e.target.value)
        break
      case "email":
        setEmail(e.target.value)
        break
      case "phone":
        setPhone(e.target.value)
        break
      case "message":
        setMessage(e.target.value)
        break
      default:
        break
    }
  }

  return (
    <PageLayout title="Contact us">
      <Panel
        className="panel--special panel--limited"
        header="Contact us"
        content="Get in touch with us via this form."
      >
        {formState === FORM_STATE.Loading && <Loader />}
        {formState === FORM_STATE.Success && (
          <div className="form__row form__row--valid">
            Thank you. We will respond as quickly as possible.
          </div>
        )}
        {formState === FORM_STATE.Failed && (
          <div className="form__row form__row--error">
            There was a problem with submitting your request. Please try again
            later
          </div>
        )}
        {formState !== FORM_STATE.Loading && !submitted && (
          <div className="form" id="form">
            <form onSubmit={onSubmit}>
              <div className="form__row">
                <label htmlFor="name">
                  Name
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={name}
                    onChange={handleChange}
                    required
                  />
                </label>
              </div>
              <div className="form__row">
                <label htmlFor="email">
                  Email
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={handleChange}
                    required
                  />
                </label>
              </div>
              <div className="form__row">
                <label htmlFor="phone">
                  Phone number
                  <input
                    id="phone"
                    name="phone"
                    type="tel"
                    value={phone}
                    onChange={handleChange}
                  />
                </label>
              </div>
              <div className="form__row">
                <label htmlFor="message">
                  Message
                  <textarea
                    id="message"
                    name="message"
                    value={message}
                    onChange={handleChange}
                    required
                  ></textarea>
                </label>
              </div>
              <div className="form__row">
                <button className="btn-secondary">Send</button>
              </div>
            </form>
          </div>
        )}
      </Panel>
    </PageLayout>
  )
}
